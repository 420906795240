import * as DI from '@/api/digital-interface/client'

export const DEMO: DI.InterceptorConfig = {
  id: 'DEMO',
  priority: 25
}

export const CAPTCHA: DI.InterceptorConfig = {
  id: 'CAPTCHA',
  priority: 80
}

export const IP_BLOCKED: DI.InterceptorConfig = {
  id: 'IP_BLOCKED',
  priority: 100
}

export const LOGGER: DI.InterceptorConfig = {
  id: 'LOGGER',
  priority: 200
}

export const TOKEN_MISMATCH: DI.InterceptorConfig = {
  id: 'TOKEN_MISMATCH',
  priority: 250
}

export const SESSION_LOST: DI.InterceptorConfig = {
  id: 'SESSION_LOST',
  priority: 300
}

export const TOKEN_NULL: DI.InterceptorConfig = {
  id: 'TOKEN_NULL',
  priority: 400
}

export const MAINTANANCE_MODE: DI.InterceptorConfig = {
  id: 'MAINTANANCE_MODE',
  priority: 500
}

export const DATE_CHANGED: DI.InterceptorConfig = {
  id: 'DATE_CHANGED',
  priority: 600
}
