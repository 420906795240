import * as Model from '../../core/1.3/model'

// Alias for convenience.
class KundenkampagnenResponse extends Model.Produkt.GetKundenkampagnen.Response { }

/**
 * Customer campaigns helper.
 */
export default class Kundenkampagnen extends KundenkampagnenResponse {
  /**
   * Instantiates a new customer campaigns helper.
   */
  public constructor (kundenkampagnen: KundenkampagnenResponse) {
    super()
    Object.assign(this, kundenkampagnen)
  }

  /**
   * Distributor codes.
   */
  public get BSCodes (): string[] {
    const kundenkampagne = this.Kundenkampagnen[0]
    if (!kundenkampagne) {
      return []
    }
    return kundenkampagne.Bonussteuerungen.map(bonus => bonus.BSCode)
  }

  /**
   * Returns parameter identified by category and subcategory.
   */
  public getParameter (hauptkategorie: string, unterkategorie?: string) {
    const kundenkampagne = this.Kundenkampagnen[0]
    if (!kundenkampagne) {
      return
    }
    const parameter = kundenkampagne.Parameter.find(parameter =>
      parameter.Hauptkategorie === hauptkategorie &&
      parameter.Unterkategorie === (unterkategorie || null)
    )
    return parameter
  }
}
