<template>
  <div class="full-height">
    <cso-friendly-captcha />
    <slot v-if="ready" />
    <div v-if="ipBlocked">
      <div class="cso-app-wrapper">
        <cso-header />
        <div class="cso-app-container">
          <cso-ip-blocked-page />
        </div>
        <cso-footer />
      </div>
    </div>
    <div v-if="maintenanceMode">
      <div class="cso-app-wrapper">
        <cso-header :simple="true"/>
        <cso-maintenance :message="maintananceModeMessage" />
        <cso-footer :no-top="true" />
      </div>
    </div>
    <div v-if="wrongDate">
      <cso-header :simple="true"/>
      <cso-wrong-date />
      <cso-footer :no-top="true" />
    </div>
    <div v-if="loading" class="cso-fetching" />
  </div>
</template>

<script lang="ts">
import { Subscription } from 'rxjs'

import store from '@/store'
import Logger from '@/api/digital-interface/interceptors/business/Logger'
import SessionLost from '@/api/digital-interface/interceptors/business/SessionLost'
import IpBlocked from '@/api/digital-interface/interceptors/business/IpBlocked'
import TokenNull from '@/api/digital-interface/interceptors/business/TokenNull'
import Maintanance from '@/api/digital-interface/interceptors/business/Maintanance'
import Captcha from '@/api/digital-interface/interceptors/business/Captcha'
import Demo from '@/api/digital-interface/interceptors/business/Demo'
import TokenMismatch from '@/api/digital-interface/interceptors/business/TokenMismatch'
import DateChanged from '@/api/digital-interface/interceptors/business/DateChanged'
import ai from '@/api/application-insights'
import di from '@/api/digital-interface'
import * as config from '@/api/digital-interface/interceptors/business/config'
import ComponentsStates from '@/common/ComponentsStates'
import Utils from '@/common/Utils'
import Session from '@/common/Session'
import StaticUrls from '@/common/StaticUrls'
import PropertyService from '@/common/PropertyService'
import LoggingService from '@/common/LoggingService'
import MultipleTabs from '@/common/MultipleTabs'
import CsoRouter from '@/api/CsoRouter'

import CsoMaintenance from '@/components/core/business/CsoMaintenance.vue'
import CsoWrongDate from '@/components/core/business/CsoWrongDate.vue'
import CsoHeader from '@/components/core/composition/CsoHeader.vue'
import CsoFooter from '@/components/core/composition/CsoFooter.vue'
import CsoIpBlockedPage from '@/components/core/technical/CsoIpBlockedPage.vue'
import CsoFriendlyCaptcha from '@/components/core/technical/CsoFriendlyCaptcha.vue'

let subscription: Subscription

export default {
  name: 'CsoInitializer',

  components: {
    CsoMaintenance,
    CsoWrongDate,
    CsoFriendlyCaptcha,
    CsoHeader,
    CsoFooter,
    CsoIpBlockedPage
  },

  data () {
    return {
      state: ComponentsStates.loading,
      maintananceModeMessage: '',
      StaticUrls
    }
  },

  computed: {
    isUserBlocked (): boolean {
      return window.location.href.indexOf('?userBlocked') !== -1
    },
    ipBlocked (): boolean {
      return this.state === ComponentsStates.ipBlocked
    },
    loading (): boolean {
      return this.state === ComponentsStates.loading
    },
    ready (): boolean {
      return this.state === ComponentsStates.ready
    },
    maintenanceMode (): boolean {
      return this.state === ComponentsStates.maintenanceMode
    },
    wrongDate (): boolean {
      return this.state === ComponentsStates.wrongDate
    }
  },

  async created () {
    if (this.isUserBlocked) {
      LoggingService.log(LoggingService.eventTypes.app, 'IP User Blocked')
      this.state = ComponentsStates.ipBlocked
      return
    }
    try {
      await this.initialize()
    } catch (error) {
      this.handleError(error)
    }
  },
  unmounted () {
    if (subscription) {
      subscription.unsubscribe()
    }
  },
  methods: {
    async initialize () {
      LoggingService.log(LoggingService.eventTypes.app, 'Initializer starts')
      // @ts-ignore
      LoggingService.log(LoggingService.eventTypes.app, 'Version: ', APP_VERSION)
      this.initializeRouter()
      this.initializeMultipleTabs()
      this.subscribeToStore()
      if (PropertyService.CSPMetaTag) {
        this.addCSPMetaTag()
      }
      if (PropertyService.aiEnabled) {
        this.initializeApplicationInsights()
      }
      this.initializeDigitalInterface()
      Session.setupGenericServices(() => this.onSessionEnd())
      if (!Session.isActive()) {
        await this.initializeSession()
      }
      LoggingService.log(LoggingService.eventTypes.app, 'Initialized')
      if (this.state === ComponentsStates.loading) {
        this.state = ComponentsStates.ready
      }
    },
    addCSPMetaTag () {
      const metaTag = document.createElement('meta')
      const tagName = 'Content-Security-Policy'
      metaTag.httpEquiv = tagName
      metaTag.content = PropertyService.CSPMetaTag
      document.head.querySelector(`meta[http-equiv=${tagName}]`)?.remove()
      document.head.append(metaTag)
    },
    initializeRouter () {
      const route = CsoRouter.findInRoutes(location.pathname)
      CsoRouter.isOpenArea = !(route && route?.components?.default && di.token?.isUserOrDemo())
    },
    initializeMultipleTabs () {
      MultipleTabs.register()
    },
    subscribeToStore () {
      subscription = store.subscribe(state => {
        if (state.maintananceMode.enabled) {
          if (state.maintananceMode.message) {
            this.maintananceModeMessage = state.maintananceMode.message
          }
          this.enableMaintenanceMode()
        }
      })
    },
    initializeApplicationInsights () {
      ai.loadAppInsights()
      ai.addTelemetryFiltering()
      ai.trackPageViewPerformance({})
      this.$router.afterEach((to, from) => {
        ai.trackRouterNavigation(to, from)
        ai.trackPageViewPerformance({})
      })
    },
    initializeDigitalInterface () {
      di.data.referenceId = `tab_id=${MultipleTabs.id}`
      di.attachInterceptor(new Maintanance(), config.MAINTANANCE_MODE)
      di.attachInterceptor(new Logger(ai), config.LOGGER)
      di.attachInterceptor(new SessionLost(di), config.SESSION_LOST)
      di.attachInterceptor(new IpBlocked(), config.IP_BLOCKED)
      di.attachInterceptor(new Demo(), config.DEMO)
      di.attachInterceptor(new TokenNull(), config.TOKEN_NULL)
      di.attachInterceptor(new Captcha(store), config.CAPTCHA)
      di.attachInterceptor(new TokenMismatch(CsoRouter), config.TOKEN_MISMATCH)
      di.attachInterceptor(new DateChanged(), config.DATE_CHANGED)
    },
    async initializeSession () {
      di.token = undefined
      di.resetParentLogId()
      store.clearAll()
      await  di.v13.authenticateAnonymous()
      if (!di.token) {
        store.clearAll()
      } else if (this.checkTimeDifference()) {
        this.state = ComponentsStates.wrongDate
      } else {
        Session.start()
      }
      if (!Utils.isAzureAgent() && !this.maintenanceMode) {
        const userAgent = Utils.getUserAgent()
        await di.v13.getInhalt()
        // @ts-ignore
        await di.v13.trackMetaInformations(userAgent, APP_VERSION)
      }
    },
    checkTimeDifference () {
      const diff = Math.abs(Utils.timeDiff(di.token!.time))
      return diff > PropertyService.maxTimeDiff
    },
    onSessionEnd () {
      Session.stop()
      store.clearAll()
      if (!di.token || di.token?.isAnonymous()) {
        window.location.reload()
      } else {
        Utils.redirectToSessionLostLoginPage()
      }
    },
    enableMaintenanceMode () {
      if (this.state !== ComponentsStates.maintenanceMode) {
        LoggingService.error(LoggingService.eventTypes.app, 'Enabling maintenance mode')
        this.state = ComponentsStates.maintenanceMode
        Session.stop()
        store.clearAll()
      }
    },
    handleError (error: unknown) {
      LoggingService.error(LoggingService.eventTypes.app, 'Initializer error', error)
      this.enableMaintenanceMode()
    }
  }
}
</script>
