import Token from '../../authentication/Token'
import { CHECK_FELDER, CREATE_ACCOUNT_AKTIVIERUNG, CREATE_NEUAUFTRAG, CREATE_UMZUG, CREATE_PRODUKTWECHSEL, CREATE_ZAEHLERSTAND, GET_DIENSTLEISTERINFORMATION, GET_PRODUKTE, GET_WECHSELPRODUKTE, GET_ZAEHLERKOSTEN, GET_ZAEHLERSTAND, DETECT_ZAEHLERSTAND, GET_ZAHLUNGSINFORMATION, UPDATE_ACCOUNT, UPDATE_ZAHLUNGSINFORMATION, GET_KUNDENDOKUMENTE, GET_OPTS, UPDATE_OPTS, GET_TOKEN_USER } from '../../core/1.3/config'
import { Prozesse as RequestProzesse, Validierung as ValidierungModel, Zaehlerstand, Zahlung, Opts as OptsModel } from '../../core/1.3/model'
import type Config from '../../core/Config'
import { RequestBody } from '../../core/model-shared'
import DigitalInterface from '../../DigitalInterface'
import ErrorMessage from '../../error/ErrorMessage'
import { compileDate, convertBase64ToFile, convertFileToBase64, type Dictionary } from '../../utility'
import { Abschlaege, Account, type Adresse, type AnredeCode, type Auftraege, type DateiIds, type DateiRequest, type DateiResponse, Dienstleister, Felder, Inhalt, Kundendokumente, Kundenkampagnen, type Lieferbeginn, type Ort, Produkt, Prozesse, Prozessinformation, type SimpleResponse, type Umzug, type UserAgent, Validierung, type ValidierungFelder, Vertraege, Vertragsliste, type Zaehlerkosten, Opts, type UserCredentials, type UserMagiclink } from './types'
import Data from './../../Data'

/**
 * Api helper.
 */
export default class Api {
  /**
   * Instantiates a new api helper.
   */
  public constructor (di: DigitalInterface) {
    this.di = di
  }

  /* Stores cached getDatei files */

  private cachedDateiFiles = {} as any

  /**
   * Authenticates anonymous.
   */
  public async authenticateAnonymous (): Promise<void> {
    if (!Data.currentAnonToken) {
      this.di.token = await this.getTokenAnonymous()
      Data.currentAnonToken = this.di.token
    } else {
      this.di.token = Data.currentAnonToken
    }
  }

  /**
    * Authenticates demo.
    */
  public async authenticateDemo (): Promise<void> {
    this.di.token = await this.getTokenDemo()
  }

  /**
   * Returns anonymous token.
   */
  public async getTokenAnonymous (): Promise<Token | undefined> {
    const token = await this.di.api13.account.getTokenAnonym({})
    return Token.createFromResponse(token, '', 'ANONYMOUS', '')
  }

  /**
   * Returns demo token.
   */
  public async getTokenDemo (): Promise<Token | undefined> {
    const token = await this.di.api13.account.getTokenAnonym({})
    return Token.createFromResponse(token, '', 'DEMO', '')
  }

  /**
   * Authenticates user from credentials.
   */
  public async authenticateUserFromCredentials (credentials: UserCredentials): Promise<void> {
    this.di.token = await this.getTokenUserFromCredentials(credentials)
  }

  /**
   * Authenticates user from magic link code.
   */
  public async authenticateUserFromMagicLink (magiclink: UserMagiclink): Promise<void> {
    this.di.token = await this.getTokenUserFromMagicLink(magiclink)
  }

  /**
   * Authenticates user from code.
   */
  public async authenticateContractAccountFromCode (code: string, browser: string, os: string): Promise<void> {
    this.di.token = await this.getTokenContractAccountFromCode(code, browser, os)
  }

  /**
   * Authenticates user from workorder.
   */
  public async authenticateContractAccountFromWorkorder (id: string, browser: string, os: string): Promise<string> {
    const { token, vk } = await this.getTokenContractAccountFromWorkorder(id, browser, os)
    this.di.token = token
    return vk
  }

  /**
   * Deauthenticates current token.
   */
  public async deauthenticate (): Promise<void> {
    await this.deleteToken()
    this.di.token = undefined
  }

  /**
   * Returns user token from credentials.
   */
  public async getTokenUserFromCredentials (credentials: UserCredentials): Promise<Token | undefined> {
    const { response } = await this.send(GET_TOKEN_USER, {
      Tenant: this.di.data.client,
      Username: credentials.username,
      Passwort: credentials.password,
      Werte: {
        TwoStepAuth: String(credentials.twoStepAuth),
        CheckOnlyBenutzerId: String(credentials.checkOnlyUserId),
        NEUE_NUTZUNGSBEDINGUNGEN_BESTAETIGT: String(credentials.termsAndConditionsConfirmed),
        BROWSER: credentials.browser,
        TOKENNAME: `${credentials.os} (${location.host})`
      }
    })
    const token = response.body.Result
    const source = response.getMessage('REMARK_KSO_MIGRATION_NOT_FINISHED') ? 'KSO_DB' : 'DAVIS_WORK'
    return Token.createFromResponse(token, token?.Username, 'USER', source)
  }

  /**
   * Returns user token from magic link code.
   */
  public async getTokenUserFromMagicLink (magiclink: UserMagiclink): Promise<Token | undefined> {
    const token = await this.di.api13.account.getTokenAllgemein({
      Tenant: this.di.data.client,
      Werte: {
        MAGICLINK: magiclink.code,
        NEUE_NUTZUNGSBEDINGUNGEN_BESTAETIGT: String(magiclink.termsAndConditionsConfirmed),
        BROWSER: magiclink.browser,
        TOKENNAME: `${magiclink.os} (${location.host})`
      }
    })
    return Token.createFromResponse(token, token?.Username, 'USER', '')
  }

  /**
   * Returns contract account token from code.
   */
  public async getTokenContractAccountFromCode (code: string, browser: string, os: string): Promise<Token | undefined> {
    const token = await this.di.api13.account.getTokenAllgemein({
      Tenant: this.di.data.client,
      Werte: {
        CODE: code,
        BROWSER: browser,
        TOKENNAME: `${os} (${location.host})`
      }
    })
    return Token.createFromResponse(token, token?.Username, 'CONTRACT_ACCOUNT', '')
  }

  /**
   * Returns contract account token from workorder.
   */
  public async getTokenContractAccountFromWorkorder (id: string, browser: string, os: string) {
    const token = await this.di.api13.account.getTokenAllgemein({
      Tenant: this.di.data.client,
      Werte: {
        ARBEITSAUFTRAG_ID: id,
        BROWSER: browser,
        TOKENNAME: `${os} (${location.host})`
      }
    })
    return {
      token: Token.createFromResponse(token, token?.Username, 'CONTRACT_ACCOUNT', ''),
      vk: token.VK
    }
  }

  /**
   * Deletes token.
   */
  public async deleteToken (): Promise<void> {
    await this.di.api13.account.deleteToken({})
  }

  /**
   * Returns user account.
   */
  public async getAccount (username: string): Promise<Account> {
    const account = await this.di.api13.account.getAccount({
      Tenant: this.di.data.client,
      Username: username
    })
    return account
  }

  /**
   * Creates log items for meta information.
   */
  public async trackMetaInformations (userAgent: UserAgent, systemVersion: string): Promise<void> {
    await this.di.api13.logging.createLogItems({
      Message: 'Metainformationen',
      LogType: 'OK',
      Username: this.di.data.username,
      System: this.di.data.client,
      SystemVersion: systemVersion,
      ExtendedData: [
        {
          Name: 'BROWSER',
          Wert: userAgent.browser
        },
        {
          Name: 'OS',
          Wert: userAgent.os
        },
        {
          Name: 'DEVICE',
          Wert: userAgent.device
        },
        {
          Name: 'USERAGENT',
          Wert: userAgent.userAgent
        }
      ]
    })
  }

  /**
   * Creates log items for failed captcha.
   */
  public async trackCaptchaFail (): Promise<void> {
    await this.di.api13.logging.createLogItems({
      Message: 'CAPTCHA Result: not human',
      LogType: 'USER_ERROR',
      Username: this.di.data.username,
      System: this.di.data.client
    })
  }

  /**
   * Creates log items for teaser.
   */
  public async trackTeaser (teaserId: string, contractAccountNumber: string, businessPartnerNumber: string): Promise<void> {
    await this.di.api13.logging.createLogItems({
      Message: 'TeaserTracking',
      LogType: 'OK',
      Username: this.di.data.username,
      System: this.di.data.client,
      ExtendedData: [
        {
          Name: 'TEASER_ID',
          Wert: teaserId
        },
        {
          Name: 'VK',
          Wert: contractAccountNumber
        },
        {
          Name: 'GP',
          Wert: businessPartnerNumber
        }
      ]
    })
  }

  /**
   * Returns a list of contracts that the given user has access to.
   */
  public async getVertragsliste (username: string): Promise<Vertragsliste> {
    const vertragsliste = await this.di.api13.vertrag.getVertragsliste({
      Username: username,
      Werte: {
        "DOKUMENTENVERSANDART": true
      }
    })
    return new Vertragsliste(vertragsliste)
  }

  /**
   * Returns contracts details.
   */
  public async getVertraege (params: {
    gp?: string[],
    vk?: string[]
  }): Promise<Vertraege> {
    const vertraege = await this.di.api13.vertrag.getVertraege({
      VK: params.vk,
      GP: params.gp
    })
    return new Vertraege(vertraege)
  }

  /**
   * Returns availability of selected processes for given contract accounts.
   */
  public async getProzesse (vk: string[], names: string[], werte?: Dictionary, userName?: string, gp?: string): Promise<Prozesse> {
    const payload: RequestProzesse.GetProzesse.Request = {
      Prozesse: names.join('|')
    }
    if (vk.length > 0 && vk[0] !== '') {
      payload.VK = vk
    } else if (gp) {
      payload.GP = [gp]
    } else if (userName) {
      payload.Username = userName
    }
    if (werte) {
      payload.Werte = werte
    }
    const prozesse = await this.di.api13.prozesse.getProzesse(payload)
    return new Prozesse(prozesse)
  }

  /**
   * Returns processes informations.
   */
  public async getProzessInformation (names: string[], vk?: string | undefined, userName?: string | undefined): Promise<Prozessinformation> {
    const payload: RequestProzesse.GetProzessinformation.Request = {
      VK: vk,
      Prozesskennzeichen: names,
      Werte: {
        USERNAME: userName
      }
    }
    const prozessinformation = await this.di.api13.prozesse.getProzessinformation(payload)
    return new Prozessinformation(prozessinformation)
  }

  /**
   * Returns content.
   */
  public async getInhalt (): Promise<Inhalt> {
    const inhalt = await this.di.api13.inhalte.getInhalt({
      Presenter: this.di.data.presenter
    })
    return new Inhalt(inhalt)
  }

  /**
   * Returns file identified by given ids.
   */
  public async getDatei (systemId: string, dateiId: string): Promise<File> {
    if (!this.cachedDateiFiles[systemId] || !this.cachedDateiFiles[systemId][dateiId]) {
      const files = await this.getDateien([{
        SystemId: systemId,
        DateiId: dateiId
      }])
      if (!this.cachedDateiFiles[systemId]) {
        this.cachedDateiFiles[systemId] = {}
      }
      this.cachedDateiFiles[systemId][dateiId] = files[0]
    }
    return this.cachedDateiFiles[systemId][dateiId]
  }

  /**
   * Returns array of files identified by given ids.
   */
  public async getDateien (ids: DateiIds[]): Promise<File[]> {
    const dateien = await this.di.api13.inhalte.getDateien({
      Dateien: ids
    })
    const files = dateien.Dateien.map(datei => this.parseDatei(datei.Datei))
    return Promise.all(files)
  }

  /**
   * Returns all customer documents related to given contract account.
   */
  public async getKundendokumente (vk: string, numberOflatestDocuments?: number | undefined): Promise<Kundendokumente> {
    const { response } = await this.send(GET_KUNDENDOKUMENTE, {
      AnzahlLetzteDokumente: numberOflatestDocuments,
      Werte: {
        VK: vk
      }
    })
    return new Kundendokumente(response)
  }

  /**
   * Returns a list of fields available in given processes.
   */
  public async getFelder (prozesse: string[]): Promise<Felder> {
    const felder = await this.di.api13.inhalte.getFelder({
      Prozess: prozesse.join('|')
    })
    return new Felder(felder)
  }

  /**
   * Checks address and returns validation outcome.
   */
  public async checkAdresse (params: {
    ort: string
    plz: string
    strasse: string
    hausnummer: string
  }): Promise<Validierung> {
    return this.checkFelder({
      ORT: params.ort,
      PLZ: params.plz,
      STRASSE: params.strasse,
      HAUSNUMMER: params.hausnummer
    })
  }

  /**
   * Checks email and returns validation outcome.
   */
  public async checkEmail (email: string): Promise<Validierung> {
    return this.checkFelder({
      EMAIL: email
    })
  }

  /**
   * Checks phone and returns validation outcome.
   */
  public async checkPhone (phone: string): Promise<Validierung> {
    return this.checkFelder({
      TELEFONNUMMER: phone
    })
  }

  /**
   * Checks birthdate and returns validation outcome.
   */
  public async checkBirthdate (birthdate: Date): Promise<Validierung> {
    return this.checkFelder({
      GEBURTSDATUM: compileDate(birthdate)
    })
  }

  /**
   * Checks bank and returns validation outcome.
   */
  public async checkBank (iban: string, bic: string): Promise<Validierung> {
    return this.checkFelder({
      IBAN: iban,
      BIC: bic
    })
  }
  /**
   * Checks name and lastname validation outcome.
   */
  public async checkNameAndSurname (name: string, lastName: string, isCompany: boolean): Promise<Validierung> {
    return this.checkFelder({
      NAME2_VORNAME: name,
      NAME1_NACHNAME: lastName,
      IST_GEWERBE: isCompany
    })
  }

  /**
   * Checks file and throws on error.
   */
  public async checkFile (file: File): Promise<void> {
    const fileCompiled = await this.compileFile(file)
    await this.di.api13.validierung.checkDatei(fileCompiled)
  }

  /**
   * Compiles javascript file into api-friendly format.
   */
  protected async compileFile (file: File): Promise<ValidierungModel.CheckDatei.Request> {
    const content64 = await convertFileToBase64(file)
    return {
      Daten: content64,
      Name: file.name
    }
  }

  /**
   * Checks fields and returns validation outcome.
   */
  public async checkFelder (felder: Partial<ValidierungFelder>): Promise<Validierung> {
    const { response } = await this.send(CHECK_FELDER, {
      Felder: felder
    })
    return new Validierung(response, felder)
  }

  /**
   * Returns a list of cities matching given criteria.
   */
  public async getOrte (sparte: string, plz: string): Promise<Ort[]> {
    const orte = await this.di.api13.adressen.getOrte({
      Sparte: sparte,
      PLZ: plz
    })
    return orte.Orte
  }

  /**
   * Updates user account.
   */
  public async updateAccount (params: {
    salutation: AnredeCode
    firstname: string
    lastname: string
    email: string
    code?: string
  }) {
    const { response } = await this.send(UPDATE_ACCOUNT, {
      Tenant: this.di.data.client,
      Vorname: params.firstname,
      Nachname: params.lastname,
      Emailadresse: params.email,
      Accountdaten: {
        ANREDE: params.salutation
      },
      Werte: {
        CODE_2FA: params.code
      }
    })
    const fa2 = response.getMessage('OK_ACCOUNT_CODE_BENOETIGT_EMAIL')
    const logoutRequired = response.getMessage('REMARK_TOKEN_INVALIDIERT_UPDATEACCOUNT')
    return {
      fa2: fa2?.FachlicheBeschreibung || '',
      logoutRequired: logoutRequired?.FachlicheBeschreibung || ''
    }
  }

  /**
   * Activate user account.
   */
  public async createAccountAktivierung (params: {
    username: string
    password: string
    code: string
  }) {
    const { response } = await this.send(CREATE_ACCOUNT_AKTIVIERUNG, {
      Tenant: this.di.data.client,
      Username: params.username,
      PasswortNeu: params.password,
      CodeEmail: params.code
    })
    return response
  }

  /**
   * Returns products matching given parameters or throws error if not found.
   */
  public async getProdukte (params: {
    kundenart: string
    sparte: string
    jahresverbrauch: string
    plz: string
    ort: string
    strasse?: string
    hausnummer?: string
    distributors?: string[],
    bp?: string
  }) {
    const { response, data } = await this.send(GET_PRODUKTE, {
      Kundenart: params.kundenart,
      Sparte: params.sparte,
      Adresse: {
        PLZ: params.plz,
        Ort: params.ort,
        Strasse: params.strasse,
        Hausnummer: params.hausnummer
      },
      Jahresverbrauch: Number.parseInt(params.jahresverbrauch),
      BSCode: params.distributors || [],
      GP: params.bp || ''
    })
    const { produkte, referenzen } = Produkt.create(data)
    if (!produkte.length) {
      throw new ErrorMessage(response)
    }
    return { produkte, referenzen }
  }

  /**
   * Returns product matching given criteria or throws if not found.
   */
  public async getProdukt (params: {
    code: string
    plz: string
    ort: string
    jahresverbrauch: string
    distributor?: string
  }): Promise<Produkt> {
    const { response, data } = await this.send(GET_PRODUKTE, {
      ProduktCode: [
        params.code
      ],
      Adresse: {
        PLZ: params.plz,
        Ort: params.ort
      },
      Jahresverbrauch: Number.parseInt(params.jahresverbrauch),
      BSCode: [
        params.distributor || ''
      ]
    })
    const { produkte } = Produkt.create(data)
    if (!produkte.length) {
      throw new ErrorMessage(response)
    }
    return produkte[0]
  }

  /**
   * Creates new order.
   */
  public async createNeuauftrag (request: Auftraege) {
    const { response } = await this.send(CREATE_NEUAUFTRAG, request)
    return {
      TransactionId: response.request.body.TransaktionsId!,
      VK: response.body.Result && response.body.Result.VK
    }
  }

  /**
   * Creates new move.
   */
  public async createUmzug (request: Umzug) {
    const { response } = await this.send(CREATE_UMZUG, request)
    return {
      TransactionId: response.request.body.TransaktionsId!
    }
  }

  /**
   * Returns delivery information for given bussiness sector.
   */
  public async getLieferbeginn (sparte: string): Promise<Lieferbeginn> {
    return this.di.api13.produkt.getLieferbeginn({
      Zaehlertyp: 'SLP',
      Sparte: sparte
    })
  }

  /**
   * Returns a list of addresses matching given criteria.
   */
  public async getAdressen (params: {
    plz?: string
    ort?: string
    strasse?: string
  }): Promise<Adresse[]> {
    const adressen = await this.di.api13.adressen.getAdresse({
      Adresse: {
        PLZ: params.plz,
        Ort: params.ort,
        Strasse: params.strasse
      }
    })
    return adressen.Adressen
  }

  /**
   * Returns customer campaigns matching given criteria.
   */
  public async getKundenkampagnen (params: {
    prozesskennzeichen: string
    sparte?: string
    kundenart?: string
    kundentyp?: string
  }): Promise<Kundenkampagnen> {
    const kundenkampagnen = await this.di.api13.produkt.getKundenkampagnen({
      Prozesskennzeichen: params.prozesskennzeichen,
      Sparte: params.sparte,
      Kundenart: params.kundenart,
      Kundentyp: params.kundentyp
    })
    return new Kundenkampagnen(kundenkampagnen)
  }

  /**
   * Returns a list of suppliers in given bussiness sector.
   */
  public async getVorversorgers (sparte: string) {
    const vorversorgers = await this.di.api13.vorversorger.getVorversorger({
      Sparte: sparte
    })
    return vorversorgers.Vorversorger
  }

  /**
   * Returns part payments for given contract account.
   */
  public async getAbschlaege (vk: string): Promise<Abschlaege> {
    const abschlaege = await this.di.api13.abschlag.getAbschlaege({
      VK: [vk]
    })
    return new Abschlaege(abschlaege)
  }

  /**
   * Updates part payment for given contract account.
   */
  public async updateAbschlag (vk: string, abschlag: number): Promise<void> {
    await this.di.api13.abschlag.updateAbschlag({
      VK: vk,
      NeuerAbschlagbetrag: abschlag
    })
  }

  /**
   * Creates a new meter Reading.
   */
  public async createZaehlerstand (request: Zaehlerstand.CreateZaehlerstand.Request) {
    const { response } = await this.send(CREATE_ZAEHLERSTAND, request)
    return {
      body: response.body
    }
  }

  /**
   * Detect meter Reding from an image.
   */
  public async detectZaehlerstand (payload: Zaehlerstand.DetectZaehlerstand.Request) {
    const { response } = await this.send(DETECT_ZAEHLERSTAND, payload)
    return {
      success: response.body.StatusCode,
      message: response.body
    }
  }

  /**
  * Get all  meter readings from  one VK.
  */
  public async getZaehlerstaende (vk: string) {
    const { response } = await this.send(GET_ZAEHLERSTAND, { VK: vk })
    return response.body.Result
  }

  /**
  * Get all  meter readings from  one VK.
   */
  public async getBankdata (vk: string[]) {
    const { response } = await this.send(GET_ZAHLUNGSINFORMATION, { VK: vk })
    return response.body.Result.Vertrag[0]
  }
  /**
  * Get all  meter readings from  one VK.
   */
  public async updateBankData (payload: Zahlung.UpdateZahlungsinformation.Request): Promise<SimpleResponse> {
    const { response } = await this.send(UPDATE_ZAHLUNGSINFORMATION, payload)
    return {
      success: response.body.StatusCode,
      message: response.body.Meldungen[0].FachlicheBeschreibung
    }
  }

  /**
   * Returns list of change products matching given criteria or throws if empty.
   */
  public async getWechselprodukte (vk: string, bscodes: string[], clientCheckActive?: boolean) {
    const requestData = {
      VK: vk,
      BSCode: bscodes
    }
    if (clientCheckActive) {
      requestData['Felder'] = {
        'CLIENT_PRUEFUNG_AKTIV': 'true'
      }
    }
    const { response, data } = await this.send(GET_WECHSELPRODUKTE, requestData)
    const { produkte, referenzen } = Produkt.create(data)
    if (!produkte.length) {
      throw new ErrorMessage(response)
    }
    return { produkte, referenzen }
  }

  /**
   * Creates a product change request.
   */
  public async createProduktwechsel (params: {
    vk: string
    produkt: Produkt
    date: Date,
    Werte: Object
  }) {
    const today = new Date()
    const { response } = await this.send(CREATE_PRODUKTWECHSEL, {
      VK: params.vk,
      Werte: params.Werte,
      Auftragsdetails: {
        VertriebspartnerName: this.di.data.client,
        BSCode: params.produkt.Bonussteuerung.BSCode,
        AngebotGueltigAb: compileDate(today),
        AngebotGueltigBis: params.produkt.MaxDatum,
        Auftragsdatum: compileDate(today),
        Produktwechseldatum: compileDate(params.date),
        ProduktCode: params.produkt.ProduktCode,
        PSTCode: params.produkt.RelevantePreis.PSTCode,
        BonusId: params.produkt.RelevanteBonus.BonusID
      }
    })
    return {
      TransactionID: response.request.body.TransaktionsId!
    }
  }

  /**
   * Returns optional agreements for given business partner.
   */
  public async getOpts (gp: string): Promise<Opts> {
    const { data } = await this.send(GET_OPTS, { GP: [gp] })
    return new Opts(data)
  }

  /**
   * Updates optional user agreements.
   */
  public async updateOpts (opts: OptsModel.UpdateOpts.Request) {
    const { response } = await this.send(UPDATE_OPTS, opts)
    return response
  }

  /**
   * Returns meter fee applicable at given address and time.
   */
  public async getZaehlerkosten (params: {
    date: Date
    ort?: string
    plz?: string
    vk?: string
  }): Promise<Zaehlerkosten> {
    const { data } = await this.send(GET_ZAEHLERKOSTEN, {
      PLZ: params.plz,
      Ort: params.ort,
      VK: params.vk
    }, {
      StandVom: params.date.toISOString().split('T')[0]
    })
    return data
  }

  /**
   * Returns informations from Funnel+ partner.
   */
  public async getFunnelPlus (): Promise<Dienstleister> {
    const { data } = await this.send(GET_DIENSTLEISTERINFORMATION, {
      Dienstleister: 'FUNNEL_PLUS',
      Information: 'STANDARD'
    })
    return new Dienstleister(data)
  }

  /**
   * Sends magic link to user email address.
   */
  public async sendMagicLink (username: string): Promise<void> {
    await this.di.api13.account.getTempPasswort({
      Tenant: this.di.data.client,
      Username: username,
      Werte: {
        PROZESSKENNZEICHEN: 'MAGICLINK'
      }
    })
  }

  /**
   * Returns informations from hippo.
   */
  public async getHippo (path: string): Promise<Dienstleister> {
    const { data } = await this.send(GET_DIENSTLEISTERINFORMATION, {
      Dienstleister: 'HIPPO',
      Information: path,
      Referenz: this.di.data.client
    })
    return new Dienstleister(data)
  }

  /**
   * Parses file returned from the api into javascript file.
   */
  protected async parseDatei (datei: DateiResponse): Promise<File> {
    const nameWithTyp = `${datei.Name}.${datei.Typ}`
    const daten64 = datei.Daten
    const mime = datei.Typ === 'svg' ? `image/svg+xml` : `application/${datei.Typ}`
    return convertBase64ToFile(nameWithTyp, daten64, mime)
  }

  /**
   * Compiles javascript file into api-friendly format.
   */
  protected async compileDatei (file: File): Promise<DateiRequest> {
    const daten64 = await convertFileToBase64(file)
    return {
      Name: file.name,
      Daten: daten64
    }
  }

  /**
   * Calls endpoint with non-standard request/response fields.
   */
  protected async send<X, Y> (endpoint: Config<X, Y>, params?: X, others: RequestBody<X> = {}) {
    const response = await this.di.send<X, Y>({
      version: '1.3',
      method: 'POST',
      endpoint: endpoint.url,
      body: {
        Request: params,
        ...others
      }
    })
    const data = response.body && response.body.Result
    return { response, data }
  }

  /**
   * The underlying digital interface instance.
   */
  protected di: DigitalInterface
}
