<template>
  <div class="collapsable-wrapper">
      <div v-for="(column, colIndex) in mappedData"
                       :key="colIndex">
        <div v-for="(item, index) in column"
             :key="index"
             :class="{'is-opened': item.isOpened}"
             class="question-row">
            <span class="title"
                  @click="toggleItem(colIndex, index)">
              <div class="title-name">
                {{item.title}}
              </div>
              <i class="icon arrow-down"></i>
            </span>
            <div class="content"
                 :style="{'max-height': item.height + 'px'}">
              <p v-html="item.content"
                  class="content-paragraph"
                 :ref="`item${colIndex}${index}`">
              </p>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
export default {
  name: 'CsoCollapsable',

  data () {
    return {
      mappedData: []
    }
  },

  props: {
    data: {
      type: Array,
      required: true
    },
    columns: {
      type: Number,
      required: false,
      default: 1
    }
  },

  created: function () {
    this.extendDataWithTogglingParameters()

    this.splitDataToChunks(this.columns)
  },

  methods: {
    splitDataToChunks (columnsNumber) {
      let result = []

      for (let i = columnsNumber; i > 0; i--) {
        result.push(this.mappedData.splice(0, Math.ceil(this.mappedData.length / i)))
      }

      this.mappedData = result
    },
    extendDataWithTogglingParameters () {
      const newVal = this.data

      for (let i = 0; i < newVal.length; i++) {
        this.mappedData.splice(i, 1, Object.assign({}, newVal[i], {
          isOpened: false,
          height: 0
        }))
      }
    },
    toggleItem (colIndex, index) {
      let item = this.mappedData[colIndex][index]

      item.isOpened = !item.isOpened
      if (item.height > 0) {
        item.height = 0
      } else {
        item.height = this.$refs[`item${colIndex}${index}`][0].clientHeight
      }
      this.mappedData[colIndex].splice(index, 1, item)
    }
  }
}
</script>

<style scoped lang="scss">
@mixin open-transition {
  transition: all 0.2s ease-out;
}

.collapsable-wrapper {
  .title,
  i:before {
    color: $black;
    font-size: 1.8rem;
    font-weight: normal;
    line-height: 2.25rem;
    cursor: pointer;
    font-family: $font-family-bold;
  }

  .question-row {
    border-bottom: 1px solid $gray-light;
  }

  .title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 20px 0 24px 0;

    & > div {
      display: inline-block;
      margin-right: 16px;
    }
  }

  .content {
    max-height: 0;
    overflow: hidden;
    @include open-transition();

    .content-paragraph {
      padding-bottom: 20px;
    }
  }

  .is-opened {

    .icon {
      transform: rotate(180deg);
    }
  }

  .icon {
      display: inline-block;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      width: 16px;
      height: 16px;
      margin-right: 8px;

      &.arrow-down {
        background-image: url("/static/icons/arrow_down.svg");
      }
    }
}
</style>
