import * as DI from '@/api/digital-interface/client'
import Session from '@/common/Session'

/**
 * Date changed interceptor.
 * 
 * Prevents request if date has changed since login.
 */
export default class DateChanged implements DI.Interceptor {
  /**
   * @inheritdoc
   */
  public async intercept (context: DI.InterceptorContext): Promise<void> {
    const { endpoint, token } = context.request
    if (
      DateChanged.ENDPOINTS.includes(endpoint) &&
      token?.isUser() &&
      this.isDateChanged(token)
    ) {
      // display wrong date message
      Session.stop()
      window.location.reload()
      return
    }
    await context.proceed()
  }

  /**
   * Returns whether date has changed.
   */
  protected isDateChanged (token: DI.Token) {
    const currentDate = new Date().toJSON().split('T')[0]
    const tokenDate = new Date(token.time).toJSON().split('T')[0]
    return currentDate != tokenDate
  }

  /**
   * Endpoints protected by this interceptor.
   */
  protected static ENDPOINTS = [
    '/Auftraege/CreateNeuauftrag',
    '/Vertrag/CreateUmzug',
    '/Auftraege/CreateProduktwechsel',
    '/Zaehlerstand/CreateZaehlerstand',
  ]
}
